import React from "react"

import { Link } from "gatsby"
import MenuEn from "./menuEn"

const NavigationEn = () => (
  <nav id="nav">
    <div className="navigation-large d-none d-md-flex justify-content-between fixed-top">
      <div className="d-flex justify-content-between name">
        <Link to="/en">Ventosa Madrid</Link>
      </div>
      <div className="d-flex justify-content-between section-nav">
        <Link to="/en/photos" className="photos-nav">
          Photos
        </Link>
        <Link to="/en/info" className="info-nav">
          Info
        </Link>
        <Link to="/en/contact" className="contact-nav">
          Contact
        </Link>
        <Link to="/es" className="es-nav">
          ES
        </Link>
        <Link to="/en" className="en-nav">
          EN
        </Link>
      </div>
    </div>
    <div className="navigation-small d-flex justify-content-between d-md-none fixed-top">
      <div className="d-flex justify-content-between name">
        <Link to="/en">Ventosa Madrid</Link>
      </div>
      <div>
        <MenuEn />
      </div>
    </div>
  </nav>
)

export default NavigationEn
