import React from "react"
import PropTypes from "prop-types"
import NavigationEn from "./navigationEn.js"
import FooterEn from "./footerEn.js"

import "../assets/scss/style.scss"

import Seo from "./seo.js"

const LayoutEn = ({ children }) => {
  return (
    <>
      <Seo />
      <NavigationEn />
      <main>{children}</main>
      <FooterEn />
    </>
  )
}

LayoutEn.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutEn
