import React from "react"
import { Link } from "gatsby"

const FooterEn = () => (
  <footer id="footer">
    <div className="footer-contain d-none d-md-flex justify-content-between">
      <div className="d-flex justify-content-between">
        <Link to="/en/contact" className="nav-link">
          Contact
        </Link>
      </div>
    </div>
  </footer>
)

export default FooterEn
