import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { Helmet } from "react-helmet"

import { Container, Row, Col } from "react-bootstrap"
import LayoutEn from "../components/layoutEn"

const PhotosEn = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "en-section photos-section" }} />
      <LayoutEn>
        <Container className="photos photos-contain">
          <Row>
            <Col md={12}>
              <p className="intro-address">
                Calle Ventosa 28, 28005, Madrid Spain
              </p>
              <p className="intro">
                Beautiful 2-bedroom apartment on Calle de la Ventosa (Madrid)
                with all the benefits of living in a central location such as
                Palacio. Completely renovated with designer furnishing and warm
                lighting. This apartment is charming and affordable and ideal
                for single professionals, postgrad students, couples, or even
                small families that want to make this their conformable home.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid className="section-grid">
          <Row>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/horiz/salon1.jpeg"
                alt="Salon 1"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/horiz/kitchen1.jpeg"
                alt="Kitchen 1"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/horiz/salon2.jpeg"
                alt="Salon 2"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/vert/bano1.jpeg"
                alt="Bano 1"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/vert/dorm1.jpeg"
                alt="Dorm 1"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/vert/dorm2.jpeg"
                alt="Dorm 2"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/horiz/salon3.jpeg"
                alt="Salon 3"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/horiz/kitchen2.jpeg"
                alt="Kitchen 2"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/horiz/salon4.jpeg"
                alt="Salon 4"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/vert/dorm3.jpeg"
                alt="Dorm 3"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/vert/dorm4.jpeg"
                alt="Dorm 4"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/vert/pasillo1.jpeg"
                alt="Pasillo 1"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/vert/pasillo2.jpeg"
                alt="Pasillo 2"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/vert/pasillo4.jpeg"
                alt="Pasillo 4"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/vert/pasillo5.jpeg"
                alt="Pasillo 5"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/barrio/alevision-co-6j7wwr1gNt4-unsplash.jpg"
                alt="Barrio 1"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/barrio/matt-dany-ffkzerqk03Y-unsplash.jpg"
                alt="Barrio 2"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
            <Col md={4}>
              <StaticImage
                src="../assets/images/piso/barrio/travis-grossen-C7tcpzQPDWs-unsplash.jpg"
                alt="Barrio 3"
                placeholder="blurred"
                layout="fullWidth"
                className="md-space"
              />
            </Col>
          </Row>
        </Container>
      </LayoutEn>
    </>
  )
}

export default PhotosEn
